import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const TabNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleTabClick = (tabName) => {
        navigate(tabName);
    };

    return (
        <div className="tab-navigation-game">
            <div className="tab-game-title">Bao lô</div>
            <div className={location.pathname === "/xsmb/lo2" ? "active" : ""} onClick={() => handleTabClick("/xsmb/lo2")}>
                Lô 2 số
            </div>
            <div className={location.pathname === "/xsmb/lo2dau" ? "active" : ""} onClick={() => handleTabClick("/xsmb/lo2dau")}>
                Lô 2 số đầu
            </div>
            <div className={location.pathname === "/xsmb/lo2so1k" ? "active" : ""} onClick={() => handleTabClick("/xsmb/lo2so1k")}>
                Lô 2 số 1K
            </div>
            <div className={location.pathname === "/xsmb/lo3" ? "active" : ""} onClick={() => handleTabClick("/xsmb/lo3")}>
                Lô 3 số
            </div>
            <div className={location.pathname === "/xsmb/lo4" ? "active" : ""} onClick={() => handleTabClick("/xsmb/lo4")}>
                Lô 4 số
            </div>

            <div className="tab-game-title">Lô xiên</div>
            <div className={location.pathname === "/xsmb/loxien2" ? "active" : ""} onClick={() => handleTabClick("/xsmb/loxien2")}>
                Lô xiên 2
            </div>
            <div className={location.pathname === "/xsmb/loxien3" ? "active" : ""} onClick={() => handleTabClick("/xsmb/loxien3")}>
                Lô xiên 3
            </div>
            <div className={location.pathname === "/xsmb/loxien4" ? "active" : ""} onClick={() => handleTabClick("/xsmb/loxien4")}>
                Lô xiên 4
            </div>

            <div className="tab-game-title">Đánh đề</div>
            <div className={location.pathname === "/xsmb/dedb" ? "active" : ""} onClick={() => handleTabClick("/xsmb/dedb")}>
                Đề đặc biệt
            </div>
            <div className={location.pathname === "/xsmb/dedaudb" ? "active" : ""} onClick={() => handleTabClick("/xsmb/dedaudb")}>
                Đề đầu đặc biệt
            </div>
            <div className={location.pathname === "/xsmb/degiai7" ? "active" : ""} onClick={() => handleTabClick("/xsmb/degiai7")}>
                Đề giải 7
            </div>
            <div className={location.pathname === "/xsmb/de1" ? "active" : ""} onClick={() => handleTabClick("/xsmb/de1")}>
                Đề giải nhất
            </div>
            <div className={location.pathname === "/xsmb/dedau1" ? "active" : ""} onClick={() => handleTabClick("/xsmb/dedau1")}>
                Đề đầu giải nhất
            </div>

            <div className="tab-game-title">Đầu đuôi</div>
            <div className={location.pathname === "/xsmb/dau" ? "active" : ""} onClick={() => handleTabClick("/xsmb/dau")}>
                Đầu
            </div>
            <div className={location.pathname === "/xsmb/duoi" ? "active" : ""} onClick={() => handleTabClick("/xsmb/duoi")}>
                Đuôi
            </div>

            <div className="tab-game-title">3 càng</div>
            <div className={location.pathname === "/xsmb/3cangdau" ? "active" : ""} onClick={() => handleTabClick("/xsmb/3cangdau")}>
                3 càng đầu
            </div>
            <div className={location.pathname === "/xsmb/3cangdb" ? "active" : ""} onClick={() => handleTabClick("/xsmb/3cangdb")}>
                3 càng đặc biệt
            </div>
            <div className={location.pathname === "/xsmb/3cang1" ? "active" : ""} onClick={() => handleTabClick("/xsmb/3cang1")}>
                3 càng giải nhất
            </div>
            <div className={location.pathname === "/xsmb/3cangdauduoi" ? "active" : ""} onClick={() => handleTabClick("/xsmb/3cangdauduoi")}>
                3 càng đầu đuôi
            </div>

            <div className="tab-game-title">4 càng</div>
            <div className={location.pathname === "/xsmb/4cangdb" ? "active" : ""} onClick={() => handleTabClick("/xsmb/4cangdb")}>
                4 càng đặc biệt
            </div>

            <div className="tab-game-title">Lô trượt</div>
            <div className={location.pathname === "/xsmb/truotxien4" ? "active" : ""} onClick={() => handleTabClick("/xsmb/truotxien4")}>
                Trượt xiên 4
            </div>
            <div className={location.pathname === "/xsmb/truotxien8" ? "active" : ""} onClick={() => handleTabClick("/xsmb/truotxien8")}>
                Trượt xiên 8
            </div>
            <div className={location.pathname === "/xsmb/truotxien10" ? "active" : ""} onClick={() => handleTabClick("/xsmb/truotxien10")}>
                Trượt xiên 10
            </div>

            {/*<div className="tab-game-title">Trò chơi thú vị</div>
            <div className={location.pathname === "/xsmb/lo2db" ? "active" : ""} onClick={() => handleTabClick("/xsmb/lo2db")}>
                Lô 2 số giải ĐB
            </div>*/}
        </div>
    );
};

export default TabNavigation;
