import styles from "./register.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import PhoneIcon from "@mui/icons-material/Phone";

function Register() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const [affiliate, setAffiliate] = useState(null);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        if (login) {
            navigate("/");
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const refParam = urlParams.get("ref");
            if (refParam) {
                setAffiliate(refParam);
            }
            if (localStorage.getItem("affiliate")) {
                setAffiliate(localStorage.getItem("affiliate"));
            }
        }
    }, []);
    const onSubmit = (data, e) => {
        e.preventDefault();
        const phoneNumberPattern = /^(0\d{9,10})$/;
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (data.username.length > 12) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối đa 12 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        if (data.password !== data.ippassword) {
            setError("ippassword", {
                type: "minLength",
                message: "Hai mật khẩu không khớp"
            });
            return;
        }
        if (!data.name) {
            /*setError("name", {
                type: "minLength",
                message: "Vui lòng nhập đầy đủ họ tên"
            });
            return;*/
            data.name = data.username;
        }
        if (!data.sdt) {
            setError("sdt", {
                type: "minLength",
                message: "Vui lòng nhập số điện thoại"
            });
            return;
        }
        if (!phoneNumberPattern.test(data.sdt)) {
            setError("sdt", {
                type: "minLength",
                message: "Định dạng số điện thoại không hợp lệ"
            });
            return;
        }
        if (data.paypwd.length < 6) {
            setError("paypwd", {
                type: "minLength",
                message: "Mật khẩu rút tiền tối thiểu 6 kí tự"
            });
            return;
        }
        let formData;
        if (affiliate == null) {
            formData = {
                username: data.username.toLowerCase(),
                password: data.password,
                code: data.code === "" ? "admin" : data.code,
                sdt: data.sdt,
                name: data.name,
                paypwd: data.paypwd
            };
        } else {
            formData = {
                username: data.username.toLowerCase(),
                password: data.password,
                code: affiliate,
                sdt: data.sdt,
                name: data.name,
                paypwd: data.paypwd
            };
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/register`, formData)
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng ký thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => navigate("/"));
            })
            .catch((err) => {
                setErr(err.response.data.message ? err.response.data.message : "Đã có lỗi xảy ra!");
            });
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword1, setShowPassword1] = useState(false);
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };
    const [showPassword2, setShowPassword2] = useState(false);
    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    return (
        <div className={styles.loginpage}>
            <div className="banner-game">
                <img src={require("../../image/register.jpg")} />
                <h1 className={styles.h1login}>ĐĂNG KÝ</h1>
            </div>
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formlogin}>
                        <div className={styles.form_group}>
                            <input type="text" {...register("username")} placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div className={styles.form_group}>
                            <input type={showPassword ? "text" : "password"} {...register("password")} placeholder="Mật khẩu" />
                            {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                        <div className={styles.form_group}>
                            <input type={showPassword1 ? "text" : "password"} {...register("ippassword")} placeholder="Nhập lại mật khẩu" />
                            {showPassword1 ? <Visibility onClick={toggleShowPassword1} /> : <VisibilityOff onClick={toggleShowPassword1} />}
                            {errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
                        </div>
                        <div className={styles.form_group}>
                            <input type="tel" {...register("sdt")} placeholder="Số điện thoại" />
                            {errors.sdt ? <p>{errors.sdt.message}</p> : null}
                        </div>
                        <div className={styles.form_group}>
                            <input type={showPassword2 ? "text" : "password"} {...register("paypwd")} placeholder="Mật khẩu rút tiền" />
                            {showPassword2 ? <Visibility onClick={toggleShowPassword2} /> : <VisibilityOff onClick={toggleShowPassword2} />}
                            {errors.paypwd ? <p>{errors.paypwd.message}</p> : null}
                        </div>
                        {affiliate == null && (
                            <div className={styles.form_group}>
                                <input type="code" {...register("code")} placeholder="Mã mời (nếu có)" />
                                {errors.code ? <p>{errors.code.message}</p> : null}
                            </div>
                        )}
                        {err ? <p>{err}</p> : null}
                        <div className={styles.form_group}>
                            <button className="btn-red" type="submit" style={{ borderRadius: "5px" }}>
                                Đăng ký
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default Register;
