import { Outlet, Navigate } from "react-router";
import { LoginStatus } from "./hook/checkLg";
import swal from "sweetalert";

const PrivateRoute = () => {
    const { login, checking } = LoginStatus();
    const alert = () => {
        swal("Vui lòng đăng nhập", "Hãy đăng nhập để tham gia trò chơi", "info");
    }
    if (checking) {
        return (
            <>
                <div></div>
            </>
        );
    }
    return login ? (
        <Outlet />
    ) : (
        <>
            <Navigate to="/login" />
            {alert()}
        </>
    );
};
export default PrivateRoute;
