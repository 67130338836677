import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperGame1 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame2 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 2.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame3 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 3.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame4 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame5 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 5.4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/miba`).then((res) => {
            setBet(res.data.t);
            if (bet != res.data.t) {
                setBet(res.data.t);
                localStorage.setItem("xsmb", JSON.stringify(res.data.t));
            }
        });
        axios.post(`${process.env.REACT_APP_API_URL}/casino/create`, {});
    }, []);
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const handleChangeMT = (e) => {
        setDateMT(e.target.value);
    };
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    const handleChangeMN = (e) => {
        setDateMN(e.target.value);
    };
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (loading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "111":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            case "61":
                a = "IMES";
                break;
            case "132":
                a = "SV388";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [playGame, setPlayGame] = useState(null);
    const { register, handleSubmit } = useForm();
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [product, setProduct] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    return (
        <>
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {swiperParams && notify && (
                <div className="bg-black">
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                    </div>
                    <div className="container" style={{ maxWidth: "800px", margin: "30px auto 0" }}>
                        <div className="box-miba">
                            <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                <img alt="" src={require("../../images/xs3mien/mienbac.png")} style={{ maxWidth: "200px" }} />
                            </div>
                            <div className="box-miba-right">
                                <div className="box-text">Miền Bắc</div>
                                <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                <div style={{ display: "flex" }}>
                                    <div className="box-miba-kq" style={{ width: "60%" }}>
                                        {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                            <div className="ball">
                                                <img alt="" src={`/images/ball/${x}.png`} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")} style={{ width: "25%", marginLeft: "15%" }}>
                                        Đặt cược
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", maxWidth: "1500px" }} className="container">
                        <div>
                            <div className="section-title" onClick={() => navigate("/lottery")}>
                                Miền Nam
                            </div>
                            <div className="list-game-xs">
                                {dataMN?.map((item, index) => (
                                    <>
                                        <Link to={`/xsmn/lo2/${item}`} key={item}>
                                            <img alt="" src={require(`../../images/xs3mien/${imageMN[index]}.png`)} className="img-xs" />
                                        </Link>
                                    </>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className="section-title" onClick={() => navigate("/lottery")}>
                                Miền Trung
                            </div>
                            <div className="list-game-xs">
                                {dataMT?.map((item, index) => (
                                    <>
                                        <Link to={`/xsmt/lo2/${item}`} key={item}>
                                            <img alt="" src={require(`../../images/xs3mien/${imageMT[index]}.png`)} className="img-xs" />
                                        </Link>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-title" onClick={() => navigate("/lottery")}>
                            Live Casino
                        </div>
                        <Swiper {...swiperGame3}>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/dg.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/ag.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/wm.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/s2.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/evolution.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "86", game_code: "EG0001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/micro.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "43", game_code: "MG0001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="container">
                        <div className="section-title" onClick={() => navigate("/sports")}>
                            Thể thao
                        </div>
                        <Swiper {...swiperGame2}>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/sports_fb_sub.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/sports_sabasports_sub.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../images/games/sports_sub_cmd368.png")} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="container">
                        <div className="section-title" onClick={() => navigate("/pvp")}>
                            Game bài
                        </div>
                        <Swiper {...swiperGame1}>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../image/v8.png")} style={{ borderRadius: "10px", width: "100%" }} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "102", game_code: "LCC001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="box-game">
                                    <div className="box-game-image">
                                        <img src={require("../../image/km.png")} style={{ borderRadius: "10px", width: "100%" }} />
                                    </div>
                                    <div className="box-game-play" onClick={() => launchGame({ product_type: "121", game_code: "KM0001" })}>
                                        <span className="box-game-start">Chơi ngay</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className="section-title2">Mini Game</div>
                        <Swiper {...swiperGame4}>
                            <SwiperSlide>
                                <Link to="/xs75s/lo2">
                                    <img alt="" src={require(`../../images/mb75s.png`)} className="img-xs" />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="/xs120s/lo2">
                                    <img alt="" src={require(`../../images/st2p.png`)} className="img-xs" />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="/xd45s">
                                    <img alt="" src={require(`../../images/xd45s.png`)} className="img-xs" style={{ transform: "scale(1.2)" }} />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="/xd60s">
                                    <img alt="" src={require(`../../images/xd1p.png`)} className="img-xs" style={{ transform: "scale(1.2)" }} />
                                </Link>
                            </SwiperSlide>
                        </Swiper>
                        <div style={{ height: "50px" }}></div>
                    </div>
                </div>
            )}

            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main" style={{ position: "relative" }}>
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "20px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "20px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#007aff", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input" style={{ maxWidth: "400px", margin: "0 auto 20px" }}>
                                    <input
                                        className="ipadd"
                                        type="text"
                                        style={{ width: "100%", borderRadius: "20px", fontSize: "15px", padding: "5px 0 5px 15px", color: "#000" }}
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div
                                        className="absolute-input"
                                        onClick={selectAll}
                                        style={{ fontSize: "13px", padding: "10px 20px", height: "90%", top: "5%", right: "2px", borderRadius: "20px" }}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"} style={{ width: "150px", height: "40px", margin: "0 auto" }}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Home;
