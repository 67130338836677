import { Link, useNavigate } from "react-router-dom";
import "../home/home.css";
import { useEffect, useState } from "react";
import axios from "axios";

function Download() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return (
        <>
            <div className="banner-game">
                <img src={require("../../image/download_bg.jpg")} />
            </div>
            <section className="section-app">
                <div className="container">
                    <div className="d-flexs">
                        <div className="qrcode-wrap" style={{ width: "100%" }}>
                            <div className="qrcode-box">
                                <div className="qrcode_text_one">
                                    <p className="text_scan">Quét Mã</p>
                                    <p>Để tải xuống ứng dụng</p>
                                </div>
                                <div className="down_info">
                                    <div className="down_icon" style={{ display: "flex", justifyContent: "space-around" }}>
                                        <span className={`down_icon_andr active`}>
                                            <svg className="svg-icon">
                                                <use xlinkHref="#icon-qrAndroid"></use>
                                            </svg>
                                        </span>
                                        <span className={`down_icon_ios active`}>
                                            <svg className="svg-icon">
                                                <use xlinkHref="#icon-qrIos"></use>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="qrcode" style={{ display: "flex", justifyContent: "space-around" }}>
                                        <img src={require("../../image/qr.jpg")} />
                                        <img src={require("../../image/qr.jpg")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Download;
